<template>
  <div class="main">
    <div class="banner"></div>
    <div class="cha content">
      <div class="title_b">
        <b>平台概述</b>
        <div class="s">
          快速构建更稳定、安全的企业生产级区块链环境，大幅减少在区块链部署、运维、管理、应用开发等方面的挑战<br />
          使客户更专注于核心业务创新，并实现业务快速上链
        </div>
        <div class="icon"></div>
      </div>
      <div class="bar">
        <div class="item" v-for="(i, index) in bl" :key="index">
          <img src="../../assets/img/productList/baas/p_title.png" alt="" />
          <div class="icon">{{ index + 1 }}</div>
          {{ i }}
        </div>
      </div>
      <div class="flex_box">
        <div class="info">
          <div class="title">平台技术演进</div>
          <div class="item" v-for="(i, index) in info1" :key="index">
            <div class="b">{{ i.t }}</div>
            <div>
              <span v-for="(e, ei) in i.list" :key="ei">{{ e }}</span>
            </div>
          </div>
        </div>

        <div class="info">
          <div class="title">平台技术演进</div>
          <div class="item" v-for="(i, index) in info2" :key="index">
            <div class="b">{{ i.t }}</div>
            <div>
              <span v-for="(e, ei) in i.list" :key="ei">{{ e }}</span>
            </div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/img/productList/baas/p_img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="core">
      <div class="title_b">
        <div class="icon"></div>
        <b>平台核心特性</b>
      </div>
      <div class="core_card">
        <div class="card">
          <div class="item" v-for="(i, index) in cl1" :key="index">{{ i }}</div>
        </div>
        <div class="card">
          <div class="item" v-for="(i, index) in cl2" :key="index">{{ i }}</div>
        </div>
      </div>
      <div class="title_b">
        <b>平台详细功能</b>
        <div class="s">
          集合管理、服务、运维监控等各类使用功能，满足各类场景下的使用需求
        </div>
        <div class="icon"></div>
      </div>
      <div class="flex_box content">
        <div class="card" v-for="(i, index) in cl3" :key="index">
          <img :src="i.img" alt="" />
          <div class="title">{{ i.title }}</div>
          <div class="flex">
            <div class="item" v-for="(e, ei) in i.list" :key="ei">
              <div class="t">{{ e.t }}</div>
              <div>
                <span v-for="(j, ji) in e.list" :key="ji" v-html="j"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fw">
      <div class="title_b">
        <b>平台架构</b>
        <div class="icon"></div>
      </div>
      <div class="content">
        <img
          src="../../assets/img/productList/baas/baas_framework.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="tp">
      <div class="title_b">
        <b>总体流程</b>
      </div>
      <div class="content">
        <img src="../../assets/img/productList/baas/baas_process.png" alt="" />
      </div>
    </div>
    <div class="sce">
      <div class="content">
        <div>
          <div class="title_b">
            <b>应用场景分类</b>
            <div class="icon"></div>
          </div>
          <img
            src="../../assets/img/productList/baas/baas_scene_title.png"
            alt=""
          />
        </div>
        <div class="card" v-for="(i, index) in sl" :key="index">
          <div class="top">
            <div class="title">{{ i.title }}</div>
            <div>{{ i.text }}</div>
          </div>
          <div class="bottom">
            <div v-for="(e, ei) in i.list" :key="ei">
              <b>{{ e.title }}</b> <span>{{ e.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="content">
        <div class="card" v-for="(i, index) in caseL" :key="index">
          <div class="icon"></div>
          <img :src="i.img" alt="" />
          <div class="title">{{ i.title }}</div>
          <div class="sub_title">{{ i.sub_title }}</div>
          <div class="text" v-for="(e,ei) in i.text" :key="ei" v-html="e"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bl: [
        "区块链部署门槛高",
        "节点/账户权限管理复杂",
        "智能合约操作不友好",
        "隐私保护和秘钥安全性",
      ],
      info1: [
        {
          t: "BaaS 1.0",
          list: [
            "基于单一的区块链架构",
            "提供原生区块链操作工具集",
            "操作和配置依赖于链框架",
          ],
        },
        {
          t: "BaaS 2.0",
          list: [
            "灵活的联盟管理",
            "支持多种异构链",
            "可视化运维和交互",
            "合约市场/跨链/隐私保护",
          ],
        },
        {
          t: "BaaS 3.0",
          list: ["跨域跨云部署", "互通主流链及平台，打造", "多业务生态系统"],
        },
      ],
      info2: [
        {
          t: "部署/运维/监控",
          list: [
            "业务子链- -键部署",
            "机构准入审批管理",
            "丰富的可配操作权限策略",
            "业务操作全程监控可追溯",
            "异常操作监控及多途径预警",
            "区块链网络运行可视化监控",
          ],
        },
        {
          t: "接口统一/数据安全",
          list: [
            "统一BAAS接服务口，多区块链平台支持",
            "支持多种共识机制",
            "支持国密",
            "多级别数据安全与隐私策略",
          ],
        },
        {
          t: "多种网络部署方式",
          list: ["支持多种云计算平台", "支持混合云和本地网络部署"],
        },
      ],
      cl1: [
        "灵活的机构/联盟/链管理",
        "支持一键部署多种异构区块链框架",
        "统一管理平台 / 统一接入平台 / 统一运维平台",
        "可视化链数据和智能合约交互",
      ],
      cl2: [
        "统一智能合约IDE(规划)",
        "支持跨链 / 支持多层次隐私保护",
        "服务容器化，支持公有云 / 私有云 / 自建机房部署",
        "支持国密 / 多种网络安全保障",
      ],
      cl3: [
        {
          title: "BaaS管理平台",
          list: [
            {
              t: "机构/联盟管理",
              list: [
                "支持管理员注册审核机构加入",
                "支持机构自主创建公开/私有联盟",
                "支持盟主邀请其他机构加入",
                "支持机构主动申请加入公开联盟",
                "支持盟主设置盟友的权限",
              ],
            },
            {
              t: "链/节点管理",
              list: [
                "支持在联盟内创建多条不同类型链",
                "支持联盟内机构自主加入链",
                "支持托管钱包/托管证书",
              ],
            },
            {
              t: "智能合约管理",
              list: ["智能合约发布", "智能合约可视化调用", "智能合约升级"],
            },
          ],
          img: require("../../assets/img/productList/baas/baas_function_1.png"),
        },
        {
          title: "BaaS底层区块链服务",
          list: [
            {
              t: "业务接入网关",
              list: [
                "支持接口鉴权",
                "支持智能合约统一接入API",
                "支持智能合约多种语言SDK开发包",
                "支持接入接口负载均衡",
                "直接接口限流控制",
              ],
            },
            {
              t: "区块链浏览器",
              list: ["支持EOS区块链浏览器", "支持Fabric区块链浏览器"],
            },
            {
              t: "跨链交互",
              list: [
                "支持xwBaaS平台内<b>Eos</b>和<b>Fabric之间</b>的数据跨链查询和合约执行",
                "支持<b>Eos/Fabric到BSN(或cmBaas)</b>的跨链合约执行/数据查询",
              ],
            },
          ],
          img: require("../../assets/img/productList/baas/baas_function_2.png"),
        },
        {
          title: "BaaS运维监控",
          list: [
            {
              t: "数据采集",
              list: [
                "全局联盟信息",
                "全局机构信息",
                "全局的节点容器资源信息",
                "机构/联盟操作记录",
                "链/节点部署记录",
                "智能合约发布升级记录",
              ],
            },
            {
              t: "监控告警通知",
              list: [
                "容器资源监控告警和通知",
                "机构账户EOS资源监控告警",
                "链状态/节点状态监控告警",
              ],
            },
            {
              t: "管理员操作",
              list: [
                "更新容器资源",
                "版本热更新",
                "EOS内存资源分配",
                "查询所有机构数据和操作记录",
              ],
            },
          ],
          img: require("../../assets/img/productList/baas/baas_function_3.png"),
        },
      ],
      sl: [
        {
          title: "其他",
          text: "区块链的技术特性使其可以应用在各类对数据真实性、透明性、准确性 有要求的垂直行业应用，或者涉及跨机构、跨部门协同的场景。",
          list: [
            {
              title: "典型应用",
              text: "典型应用：供应链协同，携号转网",
            },
          ],
        },
        {
          title: "政务民生",
          text: "区块链技术可以大力推动政府数据开放度、透明度，促进跨部门的数 据交换和共享，推进大数据在政府治理、公共服务、社会治理、宏观 调控、市场监管和城市管理等领域的应用，实现公共服务多元化、政 府治理透明化、城市管理精细化。",
          list: [
            {
              title: "典型应用",
              text: "电子政务 / 发票票据 / 医疗服务",
            },
            {
              title: "痛点/需求",
              text: "数据独立 / 可能发生篡改 / 效率不高 / 监管难",
            },
            {
              title: "区块链优势",
              text: "数据公开透明 / 不可篡改 / 观察节点 / 身份校验",
            },
          ],
        },
        {
          title: "社会公益",
          text: "新冠疫情的来袭，让慈善捐赠成为了焦点话题。区块链的去 中心化、防篡改等特点，可以为慈善机构和社会组织提供透 明公开的系统化解决方案，提升善款流转的公开性、透明性、 可信度，推动公益领域良性发展。",
          list: [
            {
              title: "典型应用",
              text: "慈善捐赠 / 扶贫帮扶",
            },
            {
              title: "痛点/需求",
              text: "虚假数据 / 监管难",
            },
            {
              title: "区块链优势",
              text: "不可篡改 / 可溯源",
            },
          ],
        },
        {
          title: "对账结算",
          text: "信息孤岛问题导致跨机构、跨系统的对账与结算成为了极其浪费人力物力 的工作，区块链的分布式账本、自动同步等特点，赋能对账结算业务自动 化能力、准确性、实时性都得到大幅提升。",
          list: [
            {
              title: "典型应用",
              text: "国际漫游结算",
            },
            {
              title: "痛点/需求",
              text: "账本不一致 / 对账延迟 / 效率低",
            },
            {
              title: "区块链优势",
              text: "共享账本 / 实时同步",
            },
          ],
        },
        {
          title: "金融服务",
          text: "金融市场中交易双方的信息不对称导致无法建立有效的信用机 制，产业链条中存在大量中心化的信用中介和信息中介，减缓 了系统运转效率，增加了资金往来成本。",
          list: [
            {
              title: "典型应用",
              text: "资产管理 / 跨境支付",
            },
            {
              title: "痛点/需求",
              text: "虚假信息 / 信息不透明 / 过于中心化 / 效率低下",
            },
            {
              title: "区块链优势",
              text: "不可篡改 / 数据公开透明 / 去中心化",
            },
          ],
        },
        {
          title: "存证服务",
          text: "区块链技术具有防止篡改、事中留痕、事后审计、 安全防护等特点，有利于提升电子证据的可信度和 真实性，降低电子数据存证成本，提高存证效率。",
          list: [
            {
              title: "典型应用",
              text: "电子政务 / 发票票据 / 医疗服务",
            },
            {
              title: "痛点/需求",
              text: "数据独立 / 可能发生篡改 / 效率不高 / 监管难",
            },
            {
              title: "区块链优势",
              text: "数据公开透明 / 不可篡改 / 观察节点 / 身份校验",
            },
          ],
        },
        {
          title: "产品溯源",
          text: "区块链打造了一种去中心、价值共享、利益公平分配的自治 价值溯源体系，提升溯源效率和可信度。",
          list: [
            {
              title: "典型应用",
              text: "电子政务 / 发票票据 / 医疗服务",
            },
            {
              title: "痛点/需求",
              text: "数据独立 / 可能发生篡改 / 效率不高 / 监管难",
            },
            {
              title: "区块链优势",
              text: "数据公开透明 / 不可篡改 / 观察节点 / 身份校验",
            },
          ],
        },
      ],
      caseL: [
        {
          img: require("../../assets/img/productList/baas/baas_plan_img_1.jpg"),
          title: "智慧医疗",
          sub_title: "SMART MEDICINE",
          text: [
            `利用区块链技术在数据共享方面的特性和优点，将商保公司/医院/卫计委/社保局/消费者/保险公司等相关方连接起来，搭建基于区块链的“普惠险+商业险+个人诊疗数据+政府监管”的共享平台。`,
            `实现了由个人发起理赔并授权平台，可访问其在医院的诊疗信息，通过平台数据交互合约获取其保单/医院诊疗记录，将保险公司赔付计算合约化，调用智能合约对区块链上数据进行赔付计算，做到自动赔付、快速赔付。`,
          ],
        },
        {
          img: require("../../assets/img/productList/baas/baas_plan_img_2.jpg"),
          title: "食品溯源",
          sub_title: "FOOD TRACEABILITY",
          text: [`通过整合各方资源，通过搭建肉菜追溯平台，利用信息化/大数据帮助农户/供应商进行生产质量分析和管控，打通产品生产—加工—物流等各个环节，基于区块链+物联网技术实现肉菜的可信溯源，对肉菜产品“从源头到餐桌”进行有效监控，并将全程品质追溯信息展现给消费者，让消费者放心购买，提高食品质量问题追踪定责的管理能力。`]},
        {
          img: require("../../assets/img/productList/baas/baas_plan_img_3.jpg"),
          title: "供应链",
          sub_title: "SUPPLY CHAIN",
          text: [`基于区块链技术低成本接入上下游中小企业，形成多中心化的供应链可信业务网络，重点关注围绕上下游中小企业融资诉求，根据其交易关系和行业特点制定基于信息流/物流/商流/资金流控制的整体金融解决方案的融资模式，提供基于供应链交易中的订单预付款、应收账款资产融资服务。`],
        },
        {
          img: require("../../assets/img/productList/baas/baas_plan_img_4.jpg"),
          title: "电子印章",
          sub_title: "ELECTRONIC SEAL",
          text:[`在电子印章被授予文件盖章后，生成唯一验真码，在PC/APP/小程序端识别验真码后，即可读取链上信息，显示“真伪”结果，及区块信息，为通过DAPP上链查询提供线索。`,`进一步实现公文/合同电子化，通过非对称加密，安全的将信息上链。主体在链上查阅电子合同，并进行授权签名，完成“盖章”。打印盖章后的电子合同，附加链上验真码。能验真的纸质文件视有效性。授权用户可解密查看电子合同的详细信息。`],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
$img: "../../assets/img/productList/baas/";
.banner {
  background-image: url($img + "banner.jpg");
}
.cha {
  .bar {
    background-color: #ebf6ff;
    border-radius: 10px;
    height: 30px;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      align-items: center;
      width: 285px;
      padding-left: 30px;
      font-size: 16px;
      line-height: 30px;
      color: #515151;
      border-left: 1px solid #d7e2eb;
      img {
        width: 34px;
      }
      .icon {
        margin-right: 10px;
        margin-left: 5px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #4275dc;
        line-height: 16px;
        text-align: center;
        color: #4275dc;
      }
      &:first-child {
        border-left: none;
      }
    }
  }
  .flex_box {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 76px;
    position: relative;
    .img {
      position: absolute;
      left: calc(50% - 140px);
      width: 280px;
      height: 700px;
    }
    .info {
      width: 412px;
      margin-top: 40px;
      &:nth-child(2) .title {
        background-color: #145ced;
      }
      .title {
        width: 260px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 26px;
        color: #ffffff;
        background-color: #2196f3;
        border-radius: 25px;
        margin: 0 auto 60px auto;
      }
      .item {
        margin-bottom: 56px;
        &:last-child {
          margin-bottom: 0;
        }
        .b {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #313131;
          margin-bottom: 26px;
        }
        span {
          font-size: 16px;
          line-height: 34px;
          color: #616161;
          margin-right: 18px;
          &::before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 11px;
            background-color: #dbc277;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.core {
  background: url($img + "baas_bg_1.jpg") 0 center / 2560px auto;
  padding-bottom: 50px;
  .title_b {
    padding-bottom: 0;
    .icon {
      margin: 15px auto;
    }
  }
  .core_card {
    width: 1194px;
    height: 365px;
    padding: 150px 33px 0 33px;
    background: url($img + "baas_core_bg.png");
    margin: 10px auto 0 auto;
    display: flex;
    justify-content: space-between;
    .card {
      width: 418px;
      height: 292px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        text-align: right;
        font-size: 18px;
        line-height: 50px;
        color: #515151;
        border-bottom: 1px solid #f1f1f1;
        &::after,
        &::before {
          display: inline-block;
          content: "";
          width: 8px;
          height: 8px;
          background-color: #2196f3;
          border-radius: 50%;
          margin-left: 12px;
        }
        &::before {
          margin-left: 0;
          margin-right: 12px;
          display: none;
        }
      }
      &:nth-child(2) .item {
        text-align: left;
        &::after {
          display: none;
        }
        &::before {
          margin-left: 0;
          display: inline-block;
        }
      }
    }
  }
  .flex_box {
    display: flex;
    justify-content: space-between;
    margin-top: 69px;
    .card {
      width: 390px;
      background-color: #ffffff;
      border-radius: 10px;
      padding-top: 120px;
      padding-bottom: 35px;
      position: relative;
      img {
        position: absolute;
        width: 128px;
        height: 148px;
        left: calc(50% - 64px);
        top: -46px;
      }
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #000000;
        text-align: center;
        margin-bottom: 20px;
      }
      .flex {
        display: flex;
        padding: 0 22px;
        height: 467px;
        flex-direction: column;
        justify-content: space-between;
        .item {
          .t {
            width: 157px;
            height: 30px;
            line-height: 30px;
            background-color: #e6f2f9;
            border-radius: 14px;
            text-align: center;
            color: #385ba3;
            font-size: 16px;
            font-weight: bold;
            margin: 0 auto 15px auto;
          }
          span {
            font-size: 14px;
            line-height: 26px;
            color: #616161;
            margin-right: 8px;
            &::before {
              display: inline-block;
              content: "";
              width: 10px;
              height: 10px;
              background: url($img + "icon.png");
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
.fw {
  padding-bottom: 50px;
}
.tp {
  background: url($img + "baas_bg_2.jpg") 0 center / 2560px auto;
  padding-bottom: 50px;
  .title_b {
    padding-top: 40px;
    padding-bottom: 25px;
    color: #ffffff;
    position: relative;
  }
}
.sce {
  background: url($img + "baas_bg_3.jpg") 0 center / 2560px auto;
  padding: 60px 0 5px 0;
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .title_b {
      text-align: left;
      padding: 0 0 38px 0;
      .icon {
        display: inline-block;
        margin: 0 0 0 15px;
        vertical-align: bottom;
      }
    }
    .card {
      width: 610px;
      box-shadow: 0px 3px 16px 0px rgba(49, 101, 177, 0.14);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 35px;
      .top {
        padding: 20px 30px 20px 60px;
        flex: 1;
        background: #ffffff;
        height: auto;
        font-size: 16px;
        line-height: 28px;
        color: #717171;
        border-radius: 10px 10px 0 0;
        .title {
          font-size: 22px;
          font-weight: bold;
          color: #000000;
          margin-bottom: 25px;
          position: relative;
          left: -40px;
          &::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: linear-gradient(10deg, #2196f3 0%, #1b65fb 100%);
            border-radius: 50%;
            box-shadow: 0 0 0 10px #e6f0f9;
            margin-right: 20px;
          }
        }
      }
      .bottom {
        font-size: 16px;
        color: #818181;
        padding: 20px 0 20px 27px;
        background: #ffffff;
        border-radius: 0 0 10px 10px;
        margin-top: 5px;
        div {
          margin-bottom: 22px;
          &:first-child b {
            color: #5946a8;
            &::before {
              background: #5946a8;
            }
          }
          &:nth-child(2) b {
            color: #d78f5f;
            &::before {
              background: #d78f5f;
            }
          }

          &:last-child {
            margin-bottom: 0;
            b {
              color: #0685b4;
              &::before {
                background: #0685b4;
              }
            }
          }
        }
        b {
          margin-right: 27px;
          &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 27px;
          }
        }
      }
    }
  }
}
.case {
  padding: 40px 0 48px 0;
  .content {
    display: flex;
    justify-content: space-between;
    .card {
      width: 285px;
      text-align: center;
      position: relative;
      .icon {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 117.5px;
        top: 175px;
        background: url($img + "baas_plan_icon.png");
      }
      .title {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        margin-top: 45px;
      }
      .sub_title {
        font-size: 14px;
        margin: 8px 0 20px 0;
        color: #799dd2;
      }
      .text {
        text-indent: 2em;
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        color: #818181;
      }
    }
  }
}
</style>